"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable camelcase */
const types_d_1 = require("@/screens/reports-screen/types.d");
/* eslint-disable max-lines */
exports.default = {
    general: {
        emptyString: ' ',
        loading: 'Carregando...',
        open: 'Abrir',
        select: 'Selecione',
        reset: 'Limpar',
        warning: 'Atenção!',
        edit: 'Editar',
        delete: 'Excluir',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        noResults: 'Sem resultados',
        pendingChanges: 'Há alterações pendentes',
        leaveWithoutSaving: 'Prosseguir sem salvar',
        saveAndLeave: 'Salvar alterações',
        newAction: 'Novo',
        perPage: 'por página',
        adminArea: 'Área administrativa',
        backofficeArea: 'Configurações Backoffice',
        getPageTitle: (title) => `Ares${title ? ` | ${title}` : ''}`,
        saveError: 'Ocorreu um erro ao salvar as informações',
        notAllFieldsAreFilled: 'Todos os campos obrigatórios devem ser preenchidos',
        fillAllFieldsAccordingly: 'Verifique que todos os campos obrigatórios foram preenchidos corretamente',
    },
    inputs: {
        genericError: 'Por favor, verifique o valor inserido.',
        fieldRequired: 'Este campo é obrigatório.',
        notNull: 'Este campo não pode ser nulo',
        noMatch: (formatTip) => `Formato esperado: ${formatTip}`,
        validation: {
            getMaxChar: (max) => `Esse campo precisa ter no máximo ${max} caracteres.`,
            getMaxValue: (max) => `O valor máximo permitido é ${max}.`,
        },
        fieldMustBeInteger: 'Deve ser inteiro',
        valueGreaterThanZero: 'Deve ser maior que 0',
        hour: 'Hora',
        futureTime: 'Esse campo não aceita datas e horários futuros',
        aircraftMustHaveIataOrIcao: 'Aeronave deve possuir Tipo da Aeronave (IATA) ou Tipo da Aeronave (ICAO)',
        maxPeriodInDays: (days) => `O período máximo é de ${days} dias.`,
    },
    login: {
        loginNotAuth: 'E-mail ou senha inválidos, tente novamente',
        email: 'E-mail',
        password: 'Senha',
        login: 'Login',
        btnLogin: 'Entrar com conta Microsoft CCR',
        btnLoginLegacy: "Login CIA's Aéreas",
    },
    logout: {
        logout: 'Logout',
        errorLogout: 'Problemas ao tentar deslogar. Por favor, tente novamente',
    },
    flightControl: {
        fetchError: 'Ocorreu um problema ao buscar informações. Tente novamente mais tarde.',
        tabBar: {
            arrival: 'Chegada',
            departure: 'Partida',
            both: 'Todos',
            towing: 'Towing',
            linked: 'Pareados',
            gantt: 'Gantt',
        },
        listFlight: {
            flight: 'VOO',
            arrival: 'CHEGADA',
            departure: 'PARTIDA',
            sta: 'STA',
            eta: 'ETA',
            touchdown: 'POUSO',
            aibt: 'CALÇO',
            from: 'ORIGEM',
            fromShort: 'ORIG.',
            type: 'NAT.',
            linkedFlight: 'PAREAMENTO',
            linkedFlightShort: 'PAREAM.',
            status: 'STATUS',
            std: 'STD',
            etd: 'ETD',
            aobt: 'DESCALÇO',
            aobtShort: 'DESCA.',
            takeoff: 'DECOLAGEM',
            takeoffShort: 'DECOL.',
            destination: 'DESTINO',
            destinationShort: 'DEST.',
            passengersCount: 'PAX',
            serviceType: 'SERV.',
            aircraft: 'MATRÍCULA',
            aircraftShort: 'MATR.',
            aircraftType: 'AERONAVE',
            gate: 'PORTÃO',
            gateShort: 'PORT.',
            reclaimBelt: 'ESTEIRA',
            reclaimBeltShort: 'ESTEI.',
            stand: 'BOX',
            runway: 'CABECEIRA',
            runwayShort: 'CABEC.',
        },
        listTowing: {
            arrivalFlight: 'Voo chegada',
            registration: 'Matrícula',
            billingStatus: 'Status',
            initialStand: 'Box inicial',
            currentStand: 'Box atual',
            arrivalDatetime: 'DH entrada',
            departureDatetime: 'DH saída',
            status: 'Situação',
            isFinished: 'Encerrado',
            onGoing: 'Em solo',
            compliant: 'Adimplente',
            overdue: 'Inadimplente',
            noInfo: 'Sem detalhes',
            history: 'Histórico',
            movement: 'Movimento',
            flight: 'Voo',
            landing: 'Pouso',
            takeOff: 'Decolagem',
            towing: 'Reboque',
        },
        gantt: {
            filter: 'Filtros',
            dateControlBackwardAriaLabel: 'Retroceder a janela de horas',
            dateControlForwardAriaLabel: 'Avançar a janela de horas',
            constructions: 'Obras',
            newConstruction: 'Nova Obra',
            editConstruciton: 'Editar Obra',
            constructionForm: {
                endingDatetime: 'Data de Término',
                beginningDatetime: 'Data de Início',
                gates: 'Portões',
                reclaimBelts: 'Esteiras',
                stands: 'Posições de Pátio',
                submitSuccess: 'Obra foi salva com sucesso',
                submitError: 'Ocorreu um erro ao salvar os dados da obra',
                deleteSuccess: 'Obra excluída com sucesso',
                deleteError: 'Ocorreu um erro ao excluir esta obra',
                selectAtLeastOneStructure: 'Selecione ao menos uma estrutura do aeroporto',
                cancel: 'Cancelar',
                create: 'Criar Obra',
                save: 'Salvar Obra',
                delete: 'Excluir Obra',
                constructionType: 'Tipo de Serviço',
            },
            undefined: 'Não definido',
            filters: {
                status: 'Status',
                originAirport: 'Origem',
                destinationAirport: 'Destino',
                aircraft: 'Matrícula',
                airline: 'Companhia Aérea',
                pnae: 'Tipo de Atendimento',
                pnaeOptionAmbulift: 'Ambulift',
                pnaeOptionStandard: 'PNAE',
            },
            openFlight: {
                arrival: 'Chegada',
                departure: 'Partida',
            },
            standRepresentation: {
                H: 'Hangar',
                R: 'Remota',
                P: 'Ponte',
                M: 'Elo/Mamuth',
                N: 'Não alocado',
            },
        },
        filter: {
            flightsFilter: 'Filtrar voos',
            towingFilter: 'Filtrar movimentações',
            arrival: 'Aeroportos de Origem',
            departure: 'Aeroportos de Destino',
            originAirport: 'Origem',
            destinationAirport: 'Destino',
            status: 'Status',
            finished: 'Exibir voos finalizados (FX - Encerrado, CA - Cancelado e DT - Alternado)',
            type: 'Natureza',
            from: 'De',
            to: 'Até',
            submit: 'Filtrar',
            reset: 'Resetar Filtros',
            filters: 'Filtros',
            serviceType: 'Serviço',
            stand: 'Box',
            initialStand: 'Box inicial',
            lastStand: 'Box atual',
            reclaimBelt: 'Esteira',
            gate: 'Portão',
            aircraftType: 'Aeronave',
            aircraft: 'Matrícula',
            airline: 'Companhia Aérea',
            linking: 'Pareamento',
            linkingOptions: {
                all: 'Todos',
                unlinked: 'Voos não pareados',
                linked: 'Voos pareados',
            },
            towingStatus: 'Situação',
            towingStatusOptions: {
                all: 'Todos',
                ongoing: 'Em solo',
                finished: 'Encerrado',
            },
            isGeneralAviation: 'Exibir apenas voos de Aviação Geral',
            generalAviationQuick: {
                main: 'Aviação Geral',
                sub: 'Exibir voos',
            },
            [types_d_1.ActivitiesFilterKey.REPORT_TYPE]: 'Relatório',
        },
        footer: {
            refreshed: 'Atualizado',
            utc: 'UTC',
            localTime: 'Horário Local',
        },
        flights: 'voos',
        newFlight: 'Novo voo',
    },
    towingDetails: {
        title: 'Movimentações da Aeronave',
        movementHistory: 'Histórico de Movimentações',
        totalTime: 'Tempo total',
        stand: 'Box',
        entrance: 'Entrada',
        entranceMovement: 'Mov. de Entrada',
        exit: 'Saída',
        exitMovement: 'Mov. de Saída',
        addMovement: 'Adicionar movimento',
        currentStand: 'Box Atual',
        currentStandExit: 'Saída Box Atual',
        nextStand: 'Box de Destino',
        nextStandEntrance: 'Entrada Box Destino',
        fetchError: 'Ocorreu um problema ao buscar informações. Tente novamente mais tarde.',
        towingAirportMismatch: 'As movimentações não pertencem ao aeroporto selecionado. Redirecionando...',
        errorSavingMovement: 'Ocorreu um erro ao salvar esta movimentação',
        successSavingMovement: 'Movimentação adicionada com sucesso',
        deleteSuccess: 'Movimentação excluída com sucesso',
        deletePermissionError: 'Você não tem permissão para excluir esta movimentação',
        deleteError: 'Ocorreu um erro ao excluir esta movimentação',
        deleteConfirm: 'Confirmar exclusão',
        saveConfirmation: 'Deseja salvar as edições de movimentação antes de sair?',
        getMovementLabel: (movementKey) => `Movimento ${movementKey}`,
    },
    navbar: {
        title: {
            login: 'Login',
            flightControl: 'Operações de Voo',
            flightEdit: 'Editar Voo',
            flightCreate: 'Criar Voo',
            towingDetails: 'Editar Movimentações',
            reports: 'Relatórios',
            default: 'Ares',
            dashboard: 'Dashboard',
            tower: 'Torre',
        },
        versionControl: 'Controle de versão',
        close: 'Fechar',
        versionNumber: 'Versão',
        versionDate: 'Data',
        logout: 'SAIR DA CONTA',
    },
    sidebarSections: {
        apoc: 'Operações de voo',
        reports: 'Relatórios',
        tower: 'Torre',
        dashboard: 'Dashboard',
    },
    emptyState: {
        noResults: 'Não há resultados.',
    },
    search: {
        placeholder: 'Buscar',
        noOptions: 'Sem resultados',
    },
    flightDetails: {
        flightCreate: {
            title: 'Criação de Voo',
        },
        flightEdit: {
            title: 'Edição de Voo',
        },
        flightOperations: 'Operações de Voo',
        fieldHasIssue: 'Campo associado à pendência',
        saveSuccess: 'Informações do voo salvas com sucesso!',
        aircraftSaveSuccess: 'Matrícula salva com sucesso!',
        aircraftTypeSaveSuccess: 'Modelo de Aeronave salvo com sucesso!',
        airportSaveSuccess: 'Aeroporto salvo com sucesso!',
        savePermissionError: 'Você não tem permissão para realizar esta ação.',
        saveConfirmation: 'Deseja salvar as edições no voo antes de sair?',
        copyError: 'Ocorreu um erro ao tentar copiar este voo',
        deleteSuccess: 'Voo excluído com sucesso!',
        deleteError: 'Ocorreu um erro ao excluir este voo',
        deletePermissionError: 'Você não tem permissão para excluir este voo',
        dataWillBeLost: 'Alguns campos terão informações perdidas.',
        unsavedDataExists: 'Há informações não salvas',
        unavailableResource: 'Este recurso não está disponível no momento',
        gateFromDifferentStand: 'Este portão pertence a uma posição de pátio (box) diferente da selecionada',
        inconsistentFields: 'É necessário confirmar as seguintes informações:',
        inconsistentData: 'Confirme os dados',
        deleteModal: 'Exclusão de Voo',
        wishToSave: 'Tem certeza que deseja salvar as alterações?',
        wishToDelete: 'Tem certeza que deseja excluir o voo abaixo?',
        wishToDeleteThisFlight: 'Tem certeza que deseja excluir este voo?',
        fieldsHaveError: 'Alguns campos não foram preenchidos ou possuem erro. Cheque as informações do formulário.',
        getCitiesError: 'Não foi possível carregar a lista de cidades.',
        getAircraftTypeError: 'Não foi possível carregar a lista de modelos de aeronave.',
        airportMismatchError: 'O voo não pertence ao aeroporto selecionado. Redirecionando...',
        flightNotFound: 'Voo não encontrado. Redirecionando...',
        pnaeRequestStatus: {
            waiting: 'Em aguardo',
            finished: 'Encerrado',
            cancelled: 'Cancelado',
            approved: 'Aprovado',
            pending: 'Pendente',
        },
        sections: {
            flight: 'Voo',
            route: 'Rota',
            passengers: 'Passageiros',
            schedule: 'Horários',
            resource: 'Recursos',
            service: 'Serviços',
            shared: 'Codeshare',
            linkedFlight: 'Pareamento',
            observations: 'Observações',
        },
        fields: {
            operation: 'Operação',
            flightNumber: 'Número do voo',
            airline: 'Companhia',
            destinationAirport: 'Destino',
            divertedDestinationAirport: 'Alternado para',
            originAirport: 'Origem',
            via: 'Via',
            status: 'Status do Voo',
            scheduledDepartureDate: 'Data de Partida Agendada',
            scheduledArrivalDate: 'Data de Chegada Agendada',
            passengersCount: 'Total de passageiros',
            passengersPnaeCount: 'Passageiros PNAE',
            pnaeOptions: 'Tipo de Atendimento',
            airportSelectedResources: 'Recurso do Aeroporto',
            currentEstimatedTimeArrival: 'Horário Estimado de Chegada',
            actualTouchdownTime: 'Horário de Pouso',
            actualInBlockTime: 'Horário de Calço',
            currentEstimatedTimeDeparture: 'Horário Estimado de Partida',
            actualOffBlockTime: 'Horário de Descalço',
            actualTakeOffTime: 'Horário de Decolagem',
            reasonCode: 'Código de justificativa',
            returnedFlight: 'Indicador de Retorno',
            aircraft: 'Matrícula',
            aircraftType: 'Aeronave',
            serviceType: 'Tipo de Serviço',
            flightType: 'Natureza',
            isPublicFlight: 'Exibição FIDS',
            aircraftTerminal: 'Pátio',
            passengerTerminal: 'Terminal',
            stand: 'Box',
            runway: 'Cabeceira',
            gate: 'Portão',
            returnReason: 'Motivo do Retorno',
            gateOpenTime: 'Abertura do Portão',
            gateCloseTime: 'Fechamento do Portão',
            reclaimBelt: 'Esteira de bagagens',
            beltOpenTime: 'Abertura da Esteira',
            beltCloseTime: 'Fechamento da Esteira',
            handling: 'Handling',
            blockedAt: 'Data de Bloqueio',
            isGeneralAviation: 'Aviação Geral',
            tailNumber: 'Número de Cauda',
            observations: 'Observações APOC',
            hybridDisembark: 'Desembarque híbrido',
            hybridDisembarkBusCount: 'Quantidade de ônibus',
            pnaeRequestScheduledAt: 'Horário necessário',
            pnaeRequestFulfilledAt: 'Horário do atendimento',
            pnaeRequestStatus: 'Status',
            pnaeRequests: 'Total de atendimentos PNAE',
            ambuliftRequests: 'Atendimentos Ambulift',
        },
        placeholders: {
            flightNumber: 'XXXX',
            airline: 'Selecione uma companhia',
            status: 'Selecione um status',
            originAirport: 'Selecione um aeroporto de origem',
            destinationAirport: 'Selecione um aeroporto de destino',
            divertedDestinationAirport: 'Selecione o novo aeroporto de destino',
            via: 'Escolha a via',
            number: 'Selecione um número',
            select: 'Selecione',
            quantity: 'Qtd',
            returnReason: 'Preencha as informações',
            tailNumber: 'XXXX-XXXX',
            hybridDisembarkBusCount: 'Qtd de ônibus',
        },
        route: {
            arrival: 'Chegada',
            departure: 'Partida',
        },
        choices: {
            yes: 'Sim',
            no: 'Não',
            public: 'Público',
            onlyStaff: 'Apenas Staff',
        },
        actions: {
            linkedFlight: 'Pareamento',
            addLinkedFlight: 'Adicionar Pareamento',
            removeLinkedFlight: 'Remover Pareamento',
            openFlightLink: 'Abrir o Voo',
            copyFlight: 'Copiar Voo',
            editFlight: 'Editar',
            deleteFlight: 'Excluir',
            goBackToFlights: 'Voltar para Voos',
            saveAndQuit: 'Salvar e Sair',
            saving: 'Salvando',
            deleting: 'Excluindo',
            save: 'Salvar',
            keepEditing: 'Continuar editando',
            createAirport: 'Cadastrar Aeroporto',
            createAircraft: 'Cadastrar Matrícula',
            editAircraft: 'Editar Matrícula',
            createAircraftType: 'Cadastrar Modelo de Aeronave',
            editAircraftType: 'Editar Modelo de Aeronave',
        },
        airportModalFields: {
            airportName: 'Nome do Aeroporto',
            icaoCode: 'Código ICAO',
            iataCode: 'Código IATA',
            city: 'Cidade',
            internationalAirport: 'Aeroporto Internacional',
            administratedAirport: 'Aeroporto Administrado',
            fidsExhibitionName: 'Nome de exibição no FIDS',
            internationalAirportInfo: 'Marque essa opção se este é um aeroporto que recebe voos internacionais.',
        },
        airportModalPlaceholders: {
            icaoCode: 'ZZZZ',
            iataCode: 'ZZZ',
        },
        aircraftFields: {
            registration: 'Matrícula da Aeronave',
            aircraftType: 'Modelo da Aeronave',
            passengerCapacity: 'Capacidade de passageiros',
            maximumTakeOffWeight: 'Peso máximo para decolagem (kg)',
        },
        aircraftTypeModal: {
            alerts: {
                iataTypeChanged: 'Alterar o valor de Tipo da Aeronave (IATA) pode impactar nas importações de voos do Score',
                icaoTypeChanged: 'Alterar o valor de Tipo da Aeronave (ICAO) pode impactar nas importações de voos do TATIC',
            },
        },
        aircraftTypeFields: {
            description: 'Descrição da Aeronave',
            iataType: 'Tipo da Aeronave (IATA)',
            icaoType: 'Tipo da Aeronave (ICAO)',
            width: 'Largura da asa (m)',
            length: 'Comprimento da Aeronave (m)',
            outerMainGearWheelSpan: 'Largura exterior dos trens de pouso principais (m)',
            groupIcaoType: 'Grupo (ICAO)',
            hasRotaryWings: 'Possui asa rotativa',
        },
        standGroupsLabel: {
            groupI: 'Grupo I',
            groupII: 'Grupo II',
            both: 'Grupo I e II',
        },
    },
    flightSchedule: {
        returnHistory: {
            addTooltip: 'Somente um retorno pode ser adicionado por vez.',
            addReturnHistory: 'ADICIONAR',
            removeHistory: 'Remover registro',
            originStandLabel: 'Box Atual',
            originStandExitTime: 'Saída Box Atual',
            destinationStandLabel: 'Box de Destino',
            destinationStandreturnTime: 'Entrada Box Destino',
            returnReasonLabel: 'Motivo Retorno',
        },
    },
    flightForm: {
        showMoreDetails: 'Mais detalhes',
        showLessDetails: 'Menos detalhes',
    },
    fieldEdit: {
        confirmChange: 'Confirmar alteração',
        cancel: 'Cancelar',
        save: 'Salvar',
        saving: 'Salvando',
        select: 'Selecionar',
        editField: (fieldName) => `Editar ${fieldName}`,
        fieldNames: {
            serviceType: 'Tipo de Serviço',
            stand: 'Box',
            aircraft: 'Matrícula',
            reclaimBelt: 'Esteira de Bagagens',
            gate: 'Portão',
            status: 'Status',
            runway: 'Cabeceira',
        },
        linkedFlightEdit: {
            linkedFlightLabel: 'Pareamento',
            fieldLabel: 'Selecione o Voo',
            add: 'Adicionar Pareamento',
            edit: 'Edição de Pareamento',
            confirmRemoval: 'Remover',
        },
    },
    images: {
        ccrLogo: 'Logo da CCR',
    },
    fids: {
        dateSeparator: (dateString) => `Voos em ${dateString}`,
        departureFlights: {
            title: 'Partidas',
            headers: {
                scheduled: 'Hora',
                destination: 'Destino',
                via: 'Escala',
                airline: 'Cia Aérea',
                flight: 'Voo',
                gate: 'Portão',
                estimated: 'Estimado',
                status: 'Status',
                aircraft: 'Matríc.',
                aircraftType: 'Tipo',
                scheduledShortened: 'STD',
                estimatedShortened: 'ETD',
                offBlockTime: 'Descal.',
                takeoffTime: 'Decol.',
                stand: 'Box',
                destinationShortened: 'Dest',
                viaShortened: 'Via',
            },
        },
        arrivalFlights: {
            title: 'Chegadas',
            headers: {
                scheduled: 'Hora',
                origin: 'Origem',
                via: 'Escala',
                airline: 'Cia Aérea',
                flight: 'Voo',
                estimated: 'Estimado',
                status: 'Status',
                reclaimBelt: 'Esteira',
                aircraft: 'Matríc.',
                aircraftType: 'Tipo',
                scheduledShortened: 'STA',
                estimatedShortened: 'ETA',
                inBlockTime: 'Calço',
                touchdownTime: 'Aterris.',
                stand: 'Box',
                originShortened: 'Orig',
                viaShortened: 'Via',
            },
        },
        gateCounter: {
            getTitle: (gateNumber) => `Portão ${gateNumber}`,
        },
        reclaimBelt: {
            getTitle: (gateNumber) => `Esteira ${gateNumber}`,
            landingText: 'Pouso às',
            headers: {
                origin: 'Origem',
                via: 'Escala',
                airline: 'Cia Aérea',
                flight: 'Voo',
                touchdownTime: 'Pouso',
            },
        },
        maintenance: {
            title: 'Em manutenção',
            bodyText: 'Pedimos desculpas, logo mais estaremos de volta. Escaneie o QR Code abaixo e acompanhe as informações dos voos pelo seu celular.',
            noQrCodeUrl: 'URL do QR Code não encontrada.',
            maintenanceScreenDisabled: 'Tela de manutenção desabilitada, favor habilitar em configurações no painel de administração.',
        },
    },
    englishFids: {
        dateSeparator: (dateString) => `Flights at ${dateString}`,
        departureFlights: {
            title: 'Departures',
            headers: {
                scheduled: 'Time',
                destination: 'Destination',
                via: 'Via',
                airline: 'Airline',
                flight: 'Flight',
                gate: 'Gate',
                estimated: 'Estimated',
                status: 'Status',
                aircraft: 'Registr.',
                aircraftType: 'Type',
                scheduledShortened: 'STD',
                estimatedShortened: 'ETD',
                offBlockTime: 'Block',
                takeoffTime: 'Take-o',
                stand: 'Box',
                destinationShortened: 'Dest',
            },
        },
        arrivalFlights: {
            title: 'Arrivals',
            headers: {
                scheduled: 'Time',
                origin: 'Origin',
                via: 'Via',
                airline: 'Airline',
                flight: 'Flight',
                estimated: 'Estimated',
                status: 'Status',
                reclaimBelt: 'Belt',
                aircraft: 'Registr.',
                aircraftType: 'Type',
                scheduledShortened: 'STA',
                estimatedShortened: 'ETA',
                inBlockTime: 'Block',
                touchdownTime: 'Land.',
                stand: 'Box',
                originShortened: 'Orig',
            },
        },
        gateCounter: {
            getTitle: (gateNumber) => `Gate ${gateNumber}`,
        },
        reclaimBelt: {
            getTitle: (gateNumber) => `Belt ${gateNumber}`,
            landingText: 'Landing at',
            headers: {
                origin: 'Origin',
                via: 'Via',
                airline: 'Airline',
                flight: 'Flight',
                touchdownTime: 'Landing',
            },
        },
        maintenance: {
            title: 'Under maintenance',
            bodyText: 'We apologize, we will be back soon. Scan the QR Code below and follow the flight information on your phone.',
            noQrCodeUrl: 'QR Code URL not found.',
            maintenanceScreenDisabled: 'Maintenance screen disabled, please enable it in the settings in the admin panel.',
        },
    },
    reports: {
        headers: {
            report: 'Relatório',
            generalView: 'Visão geral',
        },
        templates: {
            createdSuccessfully: 'Template criado com sucesso.',
            errorCreating: 'Ocorreu um erro ao criar o template.',
            updatedSuccessfully: 'Template editado com sucesso.',
            errorUpdating: 'Ocorreu um erro ao editar o template.',
            deletedSuccessfully: 'Template excluído com sucesso.',
            wishToDeleteThisTemplate: 'Tem certeza que deseja excluir este template?',
            deletePermissionError: 'Você não tem permissão para excluir esse template.',
            deleteError: 'Ocorreu um erro ao excluir o template.',
            deleteTemplate: 'Exclusão de Template',
            emailSaveSuccess: 'Email salvo com sucesso!',
            table: {
                name: 'Template',
                isActive: 'Ativado',
                reportType: 'Tipo',
                airports: 'Aeroportos',
                startDate: 'Data inicial',
                endDate: 'Data final',
                routeType: 'Oper',
                period: 'Período',
                schedules: 'Agendamento',
                airlines: 'Cia aérea',
                serviceTypes: 'Serv',
                flightTypes: 'Nat',
                fileFormats: 'Formato',
                timezone: 'Fuso hor',
            },
            menuGroupHeader: {
                generalView: 'Visão geral',
                reports: 'Relatórios',
            },
            emptyState: {
                template: {
                    noTemplates: 'Ainda não há templates cadastrados.',
                    followTheseStepsToCreateTemplate: 'Para criar um template, siga estes passos:',
                    clickAt: 'Clique em',
                    newTemplate: 'Novo',
                    toOpen: 'para abrir',
                    reportModal: 'o modal de Novo Relatório',
                    saveTemplate: 'Salvar Template',
                    toSaveConfigurations: 'para salvar suas configurações',
                    ofReports: 'de Relatório',
                },
                activities: {
                    noActivities: 'Ainda não há relatórios exportados.',
                },
                schedule: {
                    noSchedules: 'Ainda não há agendamentos.',
                    followTheseStepsToMakeASchedule: 'Para realizar um agendamento, siga estes passos:',
                    accessOneTemplateIn: 'Acesse um template na',
                    theListOfOne: 'listagem de um',
                    report: 'Relatório',
                    addADateFor: 'Adicione um data para',
                    exportClickingIn: 'exportação clicando em ',
                    schedule: 'Agendamento',
                },
            },
            form: {
                newReport: (reportName) => `Novo relatório ${reportName}`,
                name: 'Nome do template',
                isActive: 'Ativado',
                reportType: 'Relatório',
                status: 'Status',
                airports: 'Aeroportos',
                startDate: 'De',
                endDate: 'Até',
                routeType: 'Operação',
                period: 'Período',
                preset: 'Agendamento',
                airlines: 'Companhia aérea',
                serviceTypes: 'Tipo de Serviço',
                flightTypes: 'Natureza',
                fileFormats: 'Formatos',
                timezone: 'Fuso horário',
                nameRequired: 'Para salvar o template é necessário preencher um nome',
                airportRequired: 'Selecione ao menos um aeroporto',
                formatRequired: 'Selecione ao menos um formato de arquivo',
                day: 'Dia',
                dayOfMonth: 'Dia do mês',
                weekday: 'Semana',
                dayOfWeek: 'Dia da semana',
                hour: 'Hora',
                minute: 'Minutos',
                email: 'Endereço de Email',
                emails: 'Envio de Email',
                repetition: 'Repetição',
            },
            editing: {
                pageHeader: 'Edição de template',
                reportName: (reportType) => `Relatório ${reportType}`,
            },
        },
        schedules: {
            schedule: 'Agendamento',
            remove: 'Remover',
            values: {
                daily: 'Daily',
                weekly: 'Weekly',
                monthly: 'Monthly',
                hyphen: '-',
                asterisk: '*',
            },
            create: {
                day: 'Dia do mês',
                week: 'Dia da semana',
                month: 'Dia do mês',
                preset: 'Repetição',
            },
            createdSuccessfully: 'Agendamento criado com sucesso.',
            errorCreating: 'Ocorreu um erro ao criar o agendamento.',
        },
        generated: {
            pageHeader: 'Atividades',
            table: {
                fileName: 'Arquivo',
                template: 'Template',
                fileFormat: 'Formato',
                manuallyGenerated: 'Gerado Manualmente',
                generatedBy: 'Gerado por',
                status: 'Status',
                startedAt: 'Data',
                finishedAt: 'Terminado em',
                totalLines: 'Linhas',
                expirationDate: 'Validade',
                expired: 'Fora de Validade',
                download: 'Baixar',
                deliveryUrl: 'Url de acesso',
            },
            reportStarted: 'Iniciada geração do relatório. Verifique a aba de "Atividades" para mais detalhes.',
            reportError: 'Ocorreu um erro ao gerar o relatório.',
            lines: 'linhas',
        },
        menuGroupHeader: {
            generalView: 'VISÃO GERAL',
            reports: 'RELATÓRIOS',
        },
        generalViewOptions: {
            activities: 'Atividades',
            schedules: 'Agendamentos',
        },
        types: {
            rima: 'RIMA + RMA',
            punctuality: 'Pontualidade',
            turnaround: 'Turnaround',
            fulfilled: 'Voos Realizados',
            future: 'Voos Futuros',
            cancelled: 'Voos Cancelados',
            pnae: 'PNAE',
            pdo: 'PDO',
        },
        actions: {
            saveTemplate: 'Salvar Template',
            copyTemplate: 'Copiar',
            editTemplate: 'Editar',
            deleteTemplate: 'Excluir Template',
            generateReport: 'Gerar Relatório',
            shortGenerateReport: 'Gerar',
            newTemplate: 'Novo',
            downloadReport: 'Baixar Relatório',
            shortDownloadReport: 'Baixar',
            cancel: 'Cancelar',
            filter: 'Filtrar',
            newEmail: 'Cadastrar Email',
            newSchedule: 'Novo Agendamento',
            save: 'Salvar',
            saving: 'Salvando',
            generating: 'Gerando',
        },
        filters: {
            label: 'Filtros',
            apply: 'Aplicar filtros',
            fields: {
                airports: 'Aeroportos',
                routeType: 'Oper',
                period: 'Período',
                schedules: 'Agend',
                airlines: 'Cia aérea',
                serviceTypes: 'Serv',
                flightTypes: 'Nat',
                fileFormats: 'Formatos',
                fileName: 'Arquivo',
                reportType: 'Tipo',
                status: 'Status',
                initialDate: 'De',
                finalDate: 'Até',
                preset: 'Agendamento',
                weekday: 'Semana',
                day: 'Dia',
                emails: 'Emails',
            },
            all: 'Todos',
        },
    },
    cronExpressions: {
        fields: {
            singular: {
                minute: 'minuto',
                hour: 'hora',
            },
            plural: {
                minute: 'minutos',
                hour: 'horas',
            },
        },
        expressions: {
            allValues: {
                minute: 'A cada minuto',
                hour: 'A cada hora',
            },
            range: (fieldName, from, to) => `A cada ${fieldName} entre ${from} e ${to} `,
            list: 'Às',
            skippedWithInitialValue: (time, initialValue, fieldName) => `A cada ${time} ${fieldName}, a partir das ${initialValue}`,
            skippedWithoutInitialValue: (time, fieldName) => `A cada ${time} ${fieldName}`,
        },
    },
    tower: {
        lists: {
            headers: {
                arrival: 'Chegada',
                departure: 'Partida',
                scheduledArrival: 'STA',
                scheduledDeparture: 'STD',
                aircraftRegistration: 'Matrícula',
                aircraftType: 'Tipo',
                inBlock: 'Calço',
                offBlock: 'Descalço',
                stand: 'Box',
                reclaimBelt: 'Esteira',
                gate: 'Portão',
                originAirport: 'Origem',
                destinationAirport: 'Destino',
                serviceType: 'Serviço',
                pax: 'PAX',
                flightStatus: 'status',
            },
        },
    },
    dashboard: {
        listHeader: {
            airline: 'cia',
            flight: 'voo',
            aircraft: 'matrícula',
            schedule: 'horário',
            issues: 'pendência',
        },
        noIssues: 'Não há pendências.',
        panel: {
            summary: 'Visão Geral',
            settings: 'Configurações',
            arrivals: 'Chegadas',
            departures: 'Partidas',
            resetFilters: 'Limpar Filtros',
            refresh: 'Recarregar página',
            fromBeginning: 'Desde o início',
            untilEnd: 'Até o final',
        },
        settings: {
            title: 'Configurações',
            description: 'Selecione as categorias de pendências exibidas em seu painel e o grau de prioridade',
            update: 'Atualização automática',
            fetchError: 'Ocorreu um problema ao buscar informações. Tente novamente mais tarde.',
            issues: {
                all: 'Todos',
                missing_reason_code: 'Código de Justificativa',
                inconsistent_schedule: 'Horário Inconsistente',
                operation_infos: 'Informações Operacionais',
                without_gate: 'Portão de Embarque',
                without_stand: 'Posição do Pátio',
                without_reclaim_belt: 'Esteira de Restituição',
                flights_without_link: 'Voo sem Pareamento',
                empty_date_fields: 'Horários não preenchidos',
                pnae_pending_status: 'PNAE Status Pendente',
                pnae_request_not_fulfilled: 'PNAE Horário Atendimento',
                pnae_chat_without_reply: 'PNAE Chat Sem Resposta',
                tatic_placeholder_values: 'Ajustes TATIC',
                placeholder_service_type: 'Tipo de Serviço',
                placeholder_flight_type: 'Natureza do voo',
                construction_conflict: 'Conflito com Obra',
            },
            time: {
                oneMinute: '1 minuto',
                twoMinutes: '2 minutos',
                fiveMinutes: '5 minutos',
                tenMinutes: '10 minutos',
                fifteenMinutes: '15 minutos',
            },
            priority: {
                high: 'Alta',
                medium: 'Média',
                low: 'Baixa',
            },
            saveSuccess: 'Configurações salvas com sucesso!',
        },
    },
    cardRequests: {
        pnaeType: 'Tipo de atendimento',
        hasAmbulift: 'Ambulift',
        necessaryTime: 'Horário necessário',
        airportResource: 'Recurso do aeroporto',
        comment: 'Comentários',
        commentPlaceholder: 'Digite aqui alguma informação importante',
        changeNotSaved: 'Alteração não salva',
        standardPnaeAssistanceRequest: (index) => `PNAE ${index + 1}`,
        ambuliftAssistanceRequest: (index) => index !== undefined ? `Ambulift ${index + 1}` : 'Ambulift',
        unsavedAmbuliftAssistanceRequest: (index) => index !== undefined
            ? `Solicitação não salva ${index + 1} - Ambulift`
            : `Solicitação não salva - Ambulift`,
        unsavedStandardAssistanceRequest: (index) => index !== undefined
            ? `Solicitação não salva ${index + 1} - PNAE`
            : `Solicitação não salva - PNAE`,
        undoCancel: 'Desfazer Cancelamento',
        cancel: 'Cancelar',
        addRequest: 'Adicionar',
    },
    chat: {
        chatThreadList: {
            threadNames: {
                general: 'Mensagens Gerais',
            },
            labels: {
                startChat: 'Iniciar chat',
                closed: 'Encerrado',
                creatingChat: 'Abrindo chat...',
            },
        },
        chatContainer: {
            startChatMessage: 'Inicie este chat enviando uma mensagem',
            finishedFlightChatMessage: 'A operação do voo já foi encerrada',
        },
        actions: {
            openflight: 'Abrir Voo',
        },
        flightMessages: 'Mensagens do voo',
        errors: {
            flightFinished: 'A operação do voo já foi encerrada',
            changeStatus: 'Ocorreu um erro ao trocar status da solicitação',
            generic: 'Ocorreu um erro ao enviar a mensagem',
        },
        notifications: {
            systemUserName: 'ARES',
            lastThreads: 'Últimas conversas',
            noUnreadThreads: 'Não há conversas não lidas',
            unreadMessages: 'mensagens não lidas',
            unreadMessage: 'mensagem não lida',
            participantsAdded: (participantsAdded, addedBy) => `Participante ${participantsAdded} adicionado por ${addedBy}`,
        },
        chatThreadRequestStatus: {
            pending: 'Pendente',
            approved: 'Aprovado',
            cancelled: 'Cancelado',
            finished: 'Finalizado',
        },
    },
};
