"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FidsSetting = exports.FidsScreenType = exports.defaultFidsData = void 0;
exports.defaultFidsData = {
    screen: null,
    deviceSettings: null,
    airportSettings: null,
    closedResource: true,
    data: [],
    autoPaginate: false,
    initialPage: null,
    finalPage: null,
    maintenance: { hasMaintenanceScreen: false, maintenanceUrl: null },
};
var FidsScreenType;
(function (FidsScreenType) {
    FidsScreenType["PUBLIC_ARRIVAL_FLIGHTS_AIR"] = "public_arrival_flights_air";
    FidsScreenType["PUBLIC_ARRIVAL_FLIGHTS_LAND"] = "public_arrival_flights_land";
    FidsScreenType["STAFF_ARRIVAL_FLIGHTS"] = "staff_arrival_flights";
    FidsScreenType["PUBLIC_DEPARTURE_FLIGHTS_AIR"] = "public_departure_flights_air";
    FidsScreenType["PUBLIC_DEPARTURE_FLIGHTS_LAND"] = "public_departure_flights_land";
    FidsScreenType["STAFF_DEPARTURE_FLIGHTS"] = "staff_departure_flights";
    FidsScreenType["RECLAIM_BELT"] = "reclaim_belt";
    FidsScreenType["CHECK_IN_DESK"] = "check_in_desk";
    FidsScreenType["GATE_COUNTER"] = "gate_counter";
    FidsScreenType["STAND"] = "stand";
})(FidsScreenType = exports.FidsScreenType || (exports.FidsScreenType = {}));
var FidsSetting;
(function (FidsSetting) {
    FidsSetting["RELOAD_SCREEN_TIME"] = "reload_screen_time";
    FidsSetting["DEPARTURE_FLIGHTS_DISPLAYED_QUANTITY"] = "departure_flights_displayed_quantity";
    FidsSetting["DEPARTURE_FLIGHTS_LAYOUT_QUANTITY"] = "departure_flights_layout_quantity";
    FidsSetting["DEPARTURE_FLIGHTS_STAFF_DISPLAYED_QUANTITY"] = "departure_flights_staff_displayed_quantity";
    FidsSetting["DEPARTURE_FLIGHTS_STAFF_LAYOUT_QUANTITY"] = "departure_flights_staff_layout_quantity";
    FidsSetting["ARRIVAL_FLIGHTS_DISPLAYED_QUANTITY"] = "arrival_flights_displayed_quantity";
    FidsSetting["ARRIVAL_FLIGHTS_LAYOUT_QUANTITY"] = "arrival_flights_layout_quantity";
    FidsSetting["ARRIVAL_FLIGHTS_STAFF_DISPLAYED_QUANTITY"] = "arrival_flights_staff_displayed_quantity";
    FidsSetting["ARRIVAL_FLIGHTS_STAFF_LAYOUT_QUANTITY"] = "arrival_flights_staff_layout_quantity";
    FidsSetting["AIRPORT_FOOTER_MESSAGE_PRIMARY_LANGUAGE"] = "fids_footer_message_primary_language";
    FidsSetting["AIRPORT_FOOTER_MESSAGE_SECONDARY_LANGUAGE"] = "fids_footer_message_secondary_language";
    FidsSetting["DEVICE_FOOTER_MESSAGE_PRIMARY_LANGUAGE"] = "footer_message_primary_language";
    FidsSetting["DEVICE_FOOTER_MESSAGE_SECONDARY_LANGUAGE"] = "footer_message_secondary_language";
    FidsSetting["FOOTER_ENABLED"] = "footer_enabled";
    FidsSetting["FOOTER_SPEED"] = "fids_footer_speed";
    FidsSetting["PRIMARY_LANGUAGE_EXIBITION_TIME"] = "primary_language_exibition_time";
    FidsSetting["SECONDARY_LANGUAGE_EXIBITION_TIME"] = "secondary_language_exibition_time";
    FidsSetting["DESTINATION_ORIGIN_EXIBITION_TIME"] = "destination_origin_exibition_time";
    FidsSetting["VIA_EXIBITION_TIME"] = "via_exibition_time";
    FidsSetting["DISABLE_DATE_SEPARATORS"] = "disable_date_separators";
    FidsSetting["GATE_DISPLAY_LEAD_TIME"] = "gate_display_lead_time";
})(FidsSetting = exports.FidsSetting || (exports.FidsSetting = {}));
