"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnvironmentName = exports.EnvironmentType = void 0;
var EnvironmentType;
(function (EnvironmentType) {
    EnvironmentType["dev"] = "DEV";
    EnvironmentType["local"] = "LOCAL";
    EnvironmentType["homolog"] = "HOMOLOG";
})(EnvironmentType = exports.EnvironmentType || (exports.EnvironmentType = {}));
var EnvironmentName;
(function (EnvironmentName) {
    EnvironmentName["dev"] = "dev";
    EnvironmentName["local"] = "local";
    EnvironmentName["homolog"] = "hml";
    EnvironmentName["prod"] = "prd";
})(EnvironmentName = exports.EnvironmentName || (exports.EnvironmentName = {}));
