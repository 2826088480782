"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialFilterValues = exports.emptyFilterValues = exports.FilterKey = void 0;
const date_1 = require("@/services/date");
var FilterKey;
(function (FilterKey) {
    FilterKey["ORIGIN_AIRPORT"] = "originAirport";
    FilterKey["DESTINATION_AIRPORT"] = "destinationAirport";
    FilterKey["STATUS"] = "status";
    FilterKey["TYPE"] = "type";
    FilterKey["FROM"] = "from";
    FilterKey["TO"] = "to";
    FilterKey["SERVICE_TYPE"] = "serviceType";
    FilterKey["STAND"] = "stand";
    FilterKey["RECLAIM_BELT"] = "reclaimBelt";
    FilterKey["GATE"] = "gate";
    FilterKey["AIRCRAFT_TYPE"] = "aircraftType";
    FilterKey["AIRCRAFT"] = "aircraft";
    FilterKey["LINKING"] = "linking";
    FilterKey["INITIAL_STAND"] = "initialStand";
    FilterKey["LAST_STAND"] = "lastStand";
    FilterKey["TOWING_STATUS"] = "towingStatus";
    FilterKey["SHOW_FINISHED_FLIGHTS"] = "finished";
    FilterKey["SEARCH"] = "search";
    FilterKey["AIRLINE"] = "airline";
})(FilterKey = exports.FilterKey || (exports.FilterKey = {}));
exports.emptyFilterValues = {
    [FilterKey.ORIGIN_AIRPORT]: [],
    [FilterKey.DESTINATION_AIRPORT]: [],
    [FilterKey.STATUS]: [],
    [FilterKey.TYPE]: [],
    [FilterKey.FROM]: null,
    [FilterKey.TO]: null,
    [FilterKey.SERVICE_TYPE]: [],
    [FilterKey.STAND]: [],
    [FilterKey.RECLAIM_BELT]: [],
    [FilterKey.GATE]: [],
    [FilterKey.AIRCRAFT_TYPE]: [],
    [FilterKey.AIRCRAFT]: [],
    [FilterKey.LINKING]: '',
    [FilterKey.INITIAL_STAND]: [],
    [FilterKey.LAST_STAND]: [],
    [FilterKey.SHOW_FINISHED_FLIGHTS]: false,
    [FilterKey.TOWING_STATUS]: null,
    [FilterKey.SEARCH]: null,
    [FilterKey.AIRLINE]: [],
};
const getInitialFilterValues = () => ({
    ...exports.emptyFilterValues,
    [FilterKey.FROM]: (0, date_1.getCurrentDateAndAddDays)(-1),
    [FilterKey.TO]: (0, date_1.getCurrentDateAndAddDays)(1),
});
exports.getInitialFilterValues = getInitialFilterValues;
