"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightChatThreadStatus = exports.FlightChatThreadFieldName = exports.StandardPnaeRequestFieldName = exports.generalAviationIcao = exports.FlightStandGroups = exports.ServiceTypes = exports.FlightServiceTypeNatureCodes = exports.FidsFlightFields = exports.FlightStatus = exports.FieldOptionsStatus = exports.FieldOptions = exports.AircraftTypeFieldNames = exports.AircraftCategoryCodes = exports.AircraftFieldNames = exports.AirportFieldNames = exports.AirlineFieldNames = exports.FlightMovementTypes = exports.FlightFieldNameToDatetimeNames = exports.FlightDatetimeFields = exports.FlightFieldNames = void 0;
var FlightFieldNames;
(function (FlightFieldNames) {
    FlightFieldNames["id"] = "id";
    FlightFieldNames["routeType"] = "route_type";
    FlightFieldNames["identity"] = "identity";
    FlightFieldNames["flightNumber"] = "flight_number";
    FlightFieldNames["destinationAirport"] = "destination_airport";
    FlightFieldNames["divertedDestinationAirport"] = "diverted_destination_airport";
    FlightFieldNames["originAirport"] = "origin_airport";
    FlightFieldNames["vias"] = "vias";
    FlightFieldNames["airline"] = "airline";
    FlightFieldNames["currentStatus"] = "current_status";
    FlightFieldNames["flightStatuses"] = "flight_statuses";
    FlightFieldNames["scheduledArrivalDatetime"] = "scheduled_arrival_datetime";
    FlightFieldNames["scheduledDepartureDatetime"] = "scheduled_departure_datetime";
    FlightFieldNames["passengersCount"] = "passengers_count";
    FlightFieldNames["passengersPnaeCount"] = "passengers_pnae_count";
    FlightFieldNames["flightPnaeOptions"] = "flight_pnae_options";
    FlightFieldNames["pnaeOption"] = "pnae_option";
    FlightFieldNames["quantity"] = "quantity";
    FlightFieldNames["airportSelectedResources"] = "airport_selected_resources";
    FlightFieldNames["resource"] = "resource";
    FlightFieldNames["currentEstimatedTime"] = "current_estimated_time";
    FlightFieldNames["actualInBlockTime"] = "actual_in_block_datetime";
    FlightFieldNames["actualTouchdownTime"] = "actual_touchdown_datetime";
    FlightFieldNames["actualOffBlockTime"] = "actual_off_block_datetime";
    FlightFieldNames["actualTakeOffTime"] = "actual_take_off_datetime";
    FlightFieldNames["reasonCode"] = "reason_code";
    FlightFieldNames["returnedFlight"] = "return_indicator";
    FlightFieldNames["aircraft"] = "aircraft";
    FlightFieldNames["aircraftType"] = "aircraft_type";
    FlightFieldNames["serviceType"] = "service_type";
    FlightFieldNames["natureCode"] = "nature_code";
    FlightFieldNames["type"] = "type";
    FlightFieldNames["flightType"] = "flight_type";
    FlightFieldNames["isPublicFlight"] = "is_public_flight";
    FlightFieldNames["aircraftTerminal"] = "aircraft_terminal";
    FlightFieldNames["aircraftTerminalDeparture"] = "aircraft_terminal_departure";
    FlightFieldNames["aircraftTerminalArrival"] = "aircraft_terminal_arrival";
    FlightFieldNames["passengerTerminalDeparture"] = "passenger_terminal_departure";
    FlightFieldNames["passengerTerminalArrival"] = "passenger_terminal_arrival";
    FlightFieldNames["terminal"] = "terminal";
    FlightFieldNames["stand"] = "stand";
    FlightFieldNames["runway"] = "runway";
    FlightFieldNames["gate"] = "gate";
    FlightFieldNames["gates"] = "gates";
    FlightFieldNames["uniqueGateId"] = "unique_gate_id";
    FlightFieldNames["openTimeGate"] = "gate_open_datetime";
    FlightFieldNames["closeTimeGate"] = "gate_close_datetime";
    FlightFieldNames["reclaimBelt"] = "reclaim_belt_carrousel";
    FlightFieldNames["beltOpenDatetime"] = "belt_open_datetime";
    FlightFieldNames["beltCloseDatetime"] = "belt_close_datetime";
    FlightFieldNames["flightCodeshareAirlines"] = "flight_codeshare_airlines";
    FlightFieldNames["codeshareAirline"] = "airline";
    FlightFieldNames["codeshareFlightNumber"] = "flight_number";
    FlightFieldNames["linkedFlight"] = "linked_flight";
    FlightFieldNames["linkedFlightDetails"] = "linked_flight_details";
    FlightFieldNames["returnReason"] = "return_reason";
    FlightFieldNames["handlingService"] = "handling_service";
    FlightFieldNames["isShortBlocked"] = "is_short_blocked";
    FlightFieldNames["isMediumBlocked"] = "is_medium_blocked";
    FlightFieldNames["isLongBlocked"] = "is_long_blocked";
    FlightFieldNames["blockedAt"] = "short_blocked_at";
    FlightFieldNames["isGeneralAviation"] = "is_general_aviation";
    FlightFieldNames["tailNumber"] = "tail_number";
    FlightFieldNames["isImported"] = "is_imported";
    FlightFieldNames["standPosition"] = "position";
    FlightFieldNames["observations"] = "observations";
    FlightFieldNames["registration"] = "registration";
    FlightFieldNames["code"] = "code";
    FlightFieldNames["flightLeg"] = "flight_leg";
    FlightFieldNames["hybridDisembark"] = "hybrid_disembark";
    FlightFieldNames["hybridDisembarkBusCount"] = "hybrid_disembark_bus_count";
    FlightFieldNames["hybridDisembarkRequestStatus"] = "hybrid_disembark_request_status";
    FlightFieldNames["divertIndicator"] = "divert_indicator";
    FlightFieldNames["ambuliftRequests"] = "ambulift_requests";
    FlightFieldNames["ambuliftRequestNestedField"] = "ambulift_request_nested_field";
    FlightFieldNames["ambuliftRequestsPnaeType"] = "ambulift_requests__pnae_type";
    FlightFieldNames["ambuliftRequestsScheduledAt"] = "ambulift_requests__scheduled_at";
    FlightFieldNames["ambuliftRequestsFulfilledAt"] = "ambulift_requests__fulfilled_at";
    FlightFieldNames["ambuliftRequestsStatus"] = "ambulift_requests__status";
    FlightFieldNames["standardRequests"] = "standard_pnae_requests";
    FlightFieldNames["unsavedRequests"] = "unsaved_pnae_requests";
    FlightFieldNames["chatThreads"] = "chat_threads";
    FlightFieldNames["chatThread"] = "chat_thread";
    FlightFieldNames["mainAirportIcao"] = "main_airport_icao";
    FlightFieldNames["entranceMovement"] = "entrance_movement";
    FlightFieldNames["exitMovement"] = "exit_movement";
    FlightFieldNames["scoreImportHistory"] = "score_import_history";
    FlightFieldNames["flightIssues"] = "flight_issues";
})(FlightFieldNames = exports.FlightFieldNames || (exports.FlightFieldNames = {}));
exports.FlightDatetimeFields = {
    currentEstimatedTime: 'currentEstimatedTimeDate',
    actualInBlockTime: 'actualInBlockTimeDate',
    actualOffBlockTime: 'actualOffBlockTimeDate',
    actualTakeOffTime: 'actualTakeOffTimeDate',
    actualTouchdownTime: 'actualTouchdownTimeDate',
};
exports.FlightFieldNameToDatetimeNames = {
    [FlightFieldNames.currentEstimatedTime]: exports.FlightDatetimeFields.currentEstimatedTime,
    [FlightFieldNames.actualInBlockTime]: exports.FlightDatetimeFields.actualInBlockTime,
    [FlightFieldNames.actualOffBlockTime]: exports.FlightDatetimeFields.actualOffBlockTime,
    [FlightFieldNames.actualTakeOffTime]: exports.FlightDatetimeFields.actualTakeOffTime,
    [FlightFieldNames.actualTouchdownTime]: exports.FlightDatetimeFields.actualTouchdownTime,
};
exports.FlightMovementTypes = {
    landing: 'Landing',
    takeOff: 'Take off',
    towing: 'Towing',
};
exports.AirlineFieldNames = {
    icaoCode: 'icao_code',
    iataCode: 'iata_code',
    logoUrl: 'logo_url',
    logoApoc: 'logo_apoc',
};
var AirportFieldNames;
(function (AirportFieldNames) {
    AirportFieldNames["airportName"] = "name";
    AirportFieldNames["icaoCode"] = "icao_code";
    AirportFieldNames["iataCode"] = "iata_code";
    AirportFieldNames["city"] = "city";
    AirportFieldNames["isInternational"] = "is_international";
    AirportFieldNames["isAdministrated"] = "is_administrated";
    AirportFieldNames["fidsExhibitionName"] = "fids_exibition_name";
})(AirportFieldNames = exports.AirportFieldNames || (exports.AirportFieldNames = {}));
var AircraftFieldNames;
(function (AircraftFieldNames) {
    AircraftFieldNames["registration"] = "registration";
    AircraftFieldNames["aircraftType"] = "aircraft_type";
    AircraftFieldNames["passengerCapacity"] = "passenger_capacity";
    AircraftFieldNames["maximumTakeOffWeight"] = "maximum_take_off_weight";
    AircraftFieldNames["categoryCode"] = "category_code";
})(AircraftFieldNames = exports.AircraftFieldNames || (exports.AircraftFieldNames = {}));
var AircraftCategoryCodes;
(function (AircraftCategoryCodes) {
    AircraftCategoryCodes["TPX"] = "TPX";
})(AircraftCategoryCodes = exports.AircraftCategoryCodes || (exports.AircraftCategoryCodes = {}));
var AircraftTypeFieldNames;
(function (AircraftTypeFieldNames) {
    AircraftTypeFieldNames["description"] = "description";
    AircraftTypeFieldNames["iataType"] = "iata_type";
    AircraftTypeFieldNames["icaoType"] = "icao_type";
    AircraftTypeFieldNames["width"] = "width";
    AircraftTypeFieldNames["length"] = "length";
    AircraftTypeFieldNames["outerMainGearWheelSpan"] = "outer_main_gear_wheel_span";
    AircraftTypeFieldNames["groupIcaoType"] = "group_icao_type";
    AircraftTypeFieldNames["hasRotaryWings"] = "has_rotary_wings";
})(AircraftTypeFieldNames = exports.AircraftTypeFieldNames || (exports.AircraftTypeFieldNames = {}));
var FieldOptions;
(function (FieldOptions) {
    FieldOptions["AIRLINE"] = "airline";
    FieldOptions["AIRPORT"] = "airport";
    FieldOptions["TYPE"] = "type";
    FieldOptions["REASON_CODE"] = "reason_code";
    FieldOptions["AIRCRAFT"] = "aircraft";
    FieldOptions["AIRCRAFT_TYPE"] = "aircraft_type";
    FieldOptions["SERVICE_TYPE"] = "service_type";
    FieldOptions["PNAE_OPTION"] = "pnae_option";
    FieldOptions["AIRCRAFT_TERMINAL"] = "aircraft_terminal";
    FieldOptions["PASSENGER_TERMINAL"] = "passenger_terminal";
    FieldOptions["STAND"] = "stand";
    FieldOptions["RUNWAY"] = "runway";
    FieldOptions["GATE"] = "gate";
    FieldOptions["RESOURCES"] = "resources";
    FieldOptions["RECLAIM_BELT_CARROUSEL"] = "reclaim_belt_carrousel";
    FieldOptions["STATUS"] = "status";
    FieldOptions["CITY"] = "city";
    FieldOptions["OBSERVATIONS"] = "observations";
    FieldOptions["PNAE_REQUEST_STATUS"] = "pnae_request_status";
})(FieldOptions = exports.FieldOptions || (exports.FieldOptions = {}));
var FieldOptionsStatus;
(function (FieldOptionsStatus) {
    FieldOptionsStatus["ARRIVAL_STATUS"] = "arrival_status";
    FieldOptionsStatus["DEPARTURE_STATUS"] = "departure_status";
})(FieldOptionsStatus = exports.FieldOptionsStatus || (exports.FieldOptionsStatus = {}));
var FlightStatus;
(function (FlightStatus) {
    FlightStatus["estimated"] = "ES";
    FlightStatus["checkInOpen"] = "CO";
    FlightStatus["confirmed"] = "OT";
    FlightStatus["proceedToGate"] = "GA";
    FlightStatus["nowBoarding"] = "BD";
    FlightStatus["lastCall"] = "LC";
    FlightStatus["takingOff"] = "AB";
    FlightStatus["flightClosed"] = "FX";
    FlightStatus["delayed"] = "DE";
    FlightStatus["technicalDelay"] = "TD";
    FlightStatus["meteorologicalDelay"] = "DM";
    FlightStatus["cancelled"] = "CA";
    FlightStatus["notConfirmed"] = "NC";
    FlightStatus["reportToTheAirline"] = "RA";
    FlightStatus["airportClosed"] = "AC";
    FlightStatus["checkInClosed"] = "CC";
    FlightStatus["diverted"] = "DT";
    FlightStatus["landed"] = "LA";
})(FlightStatus = exports.FlightStatus || (exports.FlightStatus = {}));
var FidsFlightFields;
(function (FidsFlightFields) {
    FidsFlightFields["CONTENT_TYPE"] = "content";
    FidsFlightFields["AIRLINE"] = "airline";
    FidsFlightFields["LOGO_PATH"] = "logo_url";
    FidsFlightFields["SMALL_LOGO_FIDS"] = "small_logo_fids";
    FidsFlightFields["LARGE_LOGO_FIDS"] = "large_logo_fids";
    FidsFlightFields["ICAO_CODE"] = "icao_code";
    FidsFlightFields["CURRENT_ESTIMATED_TIME"] = "current_estimated_time";
    FidsFlightFields["CURRENT_STATUS"] = "current_status";
    FidsFlightFields["STATUS_TRANSLATED"] = "status_translated";
    FidsFlightFields["STATUS_ENGLISH"] = "status_english";
    FidsFlightFields["DESTINATION_AIRPORT"] = "destination_airport";
    FidsFlightFields["AIRPORT_CODE"] = "iata_code";
    FidsFlightFields["ORIGIN_AIRPORT"] = "origin_airport";
    FidsFlightFields["AIRPORT"] = "airport";
    FidsFlightFields["AIRPORT_NAME"] = "fids_exibition_name";
    FidsFlightFields["GATE"] = "gate";
    FidsFlightFields["CODE"] = "code";
    FidsFlightFields["FLIGHT_NUMBER"] = "flight_number";
    FidsFlightFields["SCHEDULED_DEPARTURE_DATETIME"] = "scheduled_departure_datetime";
    FidsFlightFields["SCHEDULED_ARRIVAL_DATETIME"] = "scheduled_arrival_datetime";
    FidsFlightFields["VIA"] = "via";
    FidsFlightFields["RECLAIM_BELT"] = "reclaim_belt_carrousel";
    FidsFlightFields["AIRCRAFT"] = "aircraft";
    FidsFlightFields["AIRCRAFT_TYPE"] = "aircraft_type";
    FidsFlightFields["ACTUAL_OFF_BLOCK_TIME"] = "actual_off_block_datetime";
    FidsFlightFields["ACTUAL_TAKE_OFF_TIME"] = "actual_take_off_datetime";
    FidsFlightFields["STAND"] = "stand";
    FidsFlightFields["ACTUAL_IN_BLOCK_TIME"] = "actual_in_block_datetime";
    FidsFlightFields["ACTUAL_TOUCHDOWN_TIME"] = "actual_touchdown_datetime";
    FidsFlightFields["TYPE_PRIMARY_LANGUAGE"] = "type_primary_language";
    FidsFlightFields["TYPE_SECONDARY_LANGUAGE"] = "type_secondary_language";
})(FidsFlightFields = exports.FidsFlightFields || (exports.FidsFlightFields = {}));
var FlightServiceTypeNatureCodes;
(function (FlightServiceTypeNatureCodes) {
    FlightServiceTypeNatureCodes["humanitarianGeral"] = "HG";
    FlightServiceTypeNatureCodes["humanitarianCommercial"] = "HC";
    FlightServiceTypeNatureCodes["loadMailCharter"] = "CGO";
    FlightServiceTypeNatureCodes["loadMailExtra"] = "CGO";
    FlightServiceTypeNatureCodes["loadRegular"] = "CGO";
    FlightServiceTypeNatureCodes["mailRegular"] = "CGO";
    FlightServiceTypeNatureCodes["passengerCharter"] = "PAX";
    FlightServiceTypeNatureCodes["passengerExtra"] = "PAX";
    FlightServiceTypeNatureCodes["passengerRegular"] = "PAX";
    FlightServiceTypeNatureCodes["passengerLoadExtra"] = "PAX";
    FlightServiceTypeNatureCodes["passengerLoadRegular"] = "PAX";
    FlightServiceTypeNatureCodes["passengerLoadMailCharter"] = "PAX";
    FlightServiceTypeNatureCodes["technicalFlight"] = "PAX";
    FlightServiceTypeNatureCodes["technicalStop"] = "PAX";
    FlightServiceTypeNatureCodes["transfer"] = "PAX";
    FlightServiceTypeNatureCodes["airTaxi"] = "GA";
    FlightServiceTypeNatureCodes["generalAviation"] = "GA";
    FlightServiceTypeNatureCodes["specialFlightGovernmental"] = "GA";
    FlightServiceTypeNatureCodes["training"] = "GA";
    FlightServiceTypeNatureCodes["military"] = "IL";
})(FlightServiceTypeNatureCodes = exports.FlightServiceTypeNatureCodes || (exports.FlightServiceTypeNatureCodes = {}));
var ServiceTypes;
(function (ServiceTypes) {
    ServiceTypes["A__CARGO_EXTRA"] = "A";
    ServiceTypes["B__HUMANITARIAN_GERAL"] = "B";
    ServiceTypes["C__PAX_CHARTER"] = "C";
    ServiceTypes["D__GENERAL_AVIATION"] = "D";
    ServiceTypes["E__SPECIAL_FLIGHT_GOV"] = "E";
    ServiceTypes["F__CARGO_REGULAR"] = "F";
    ServiceTypes["G__PAX_EXTRA"] = "G";
    ServiceTypes["H__CARGO_MAIL_CHARTER"] = "H";
    ServiceTypes["J__PAX_REGULAR"] = "J";
    ServiceTypes["K__TRAINING_FLIGHT"] = "K";
    ServiceTypes["L__PAX_CARGO_MAIL_CHARTER"] = "L";
    ServiceTypes["M__MAIL_REGULAR"] = "M";
    ServiceTypes["N__AIR_TAXI"] = "N";
    ServiceTypes["O__HUMANITARIAN_COMMERCIAL"] = "O";
    ServiceTypes["P__POSITIONING_FLIGHTS"] = "P";
    ServiceTypes["Q__PAX_CARGO_REGULAR"] = "Q";
    ServiceTypes["R__PAX_CARGO_EXTRA"] = "R";
    ServiceTypes["T__TECHNICAL_FLIGHT"] = "T";
    ServiceTypes["W__MILITARY_FLIGHT"] = "W";
    ServiceTypes["X__TECHNICAL_STOP"] = "X";
    ServiceTypes["Y__ALTERNATED_FLIGHT"] = "Y";
    ServiceTypes["Z__RETURNED_FLIGHT"] = "Z";
    ServiceTypes["TATIC_GENERATED_FLIGHT"] = "#";
})(ServiceTypes = exports.ServiceTypes || (exports.ServiceTypes = {}));
var FlightStandGroups;
(function (FlightStandGroups) {
    FlightStandGroups["groupI"] = "GROUP_I";
    FlightStandGroups["groupII"] = "GROUP_II";
    FlightStandGroups["both"] = "BOTH";
})(FlightStandGroups = exports.FlightStandGroups || (exports.FlightStandGroups = {}));
exports.generalAviationIcao = 'ZZZ';
var StandardPnaeRequestFieldName;
(function (StandardPnaeRequestFieldName) {
    StandardPnaeRequestFieldName["id"] = "id";
    StandardPnaeRequestFieldName["pnaeType"] = "pnae_type";
    StandardPnaeRequestFieldName["airportResource"] = "requested_airport_resource";
})(StandardPnaeRequestFieldName = exports.StandardPnaeRequestFieldName || (exports.StandardPnaeRequestFieldName = {}));
var FlightChatThreadFieldName;
(function (FlightChatThreadFieldName) {
    FlightChatThreadFieldName["id"] = "id";
    FlightChatThreadFieldName["threadId"] = "thread_id";
    FlightChatThreadFieldName["threadStatus"] = "thread_status";
    FlightChatThreadFieldName["flightId"] = "flight_id";
    FlightChatThreadFieldName["message"] = "message";
})(FlightChatThreadFieldName = exports.FlightChatThreadFieldName || (exports.FlightChatThreadFieldName = {}));
var FlightChatThreadStatus;
(function (FlightChatThreadStatus) {
    FlightChatThreadStatus["ACTIVE"] = "active";
    FlightChatThreadStatus["CLOSED"] = "closed";
})(FlightChatThreadStatus = exports.FlightChatThreadStatus || (exports.FlightChatThreadStatus = {}));
