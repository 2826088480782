"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeGeneralAviationState = exports.getGeneralAviationState = exports.updateGeneralAviationState = void 0;
const auth_1 = require("@/auth");
const GENERAL_AVIATION_ONLY = 'general-aviation-only';
const removeGeneralAviationState = () => {
    localStorage.removeItem(GENERAL_AVIATION_ONLY);
};
exports.removeGeneralAviationState = removeGeneralAviationState;
const updateGeneralAviationState = (state) => {
    if (!state)
        return removeGeneralAviationState();
    return localStorage.setItem(GENERAL_AVIATION_ONLY, state.toString());
};
exports.updateGeneralAviationState = updateGeneralAviationState;
const getGeneralAviationState = () => {
    if (!(0, auth_1.isAuthenticated)())
        return null;
    return Boolean(localStorage.getItem(GENERAL_AVIATION_ONLY));
};
exports.getGeneralAviationState = getGeneralAviationState;
